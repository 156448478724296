<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div class="row">
            <div class="col-6">
                <ASkeleton
                    active
                    :paragraph="{ rows: 5 }"
                    :loading="state.loading">
                    <ADescriptions bordered size="small">
                        <ADescriptions-item label="Tanggal Survei" :span="3">{{ state.detail.tgl_survey }}</ADescriptions-item>
                        <ADescriptions-item label="Toko" :span="3">{{ state.detail.nama_toko }}</ADescriptions-item>
                        <ADescriptions-item label="Kapasitas Gudang Toko" :span="3">{{ 'example' }}</ADescriptions-item>
                        <ADescriptions-item label="Kapasitas Pernjualan Perbulan" :span="3">{{ 'example' }}</ADescriptions-item>
                        <ADescriptions-item label="Salesman" :span="3">{{ state.detail.sales }}</ADescriptions-item>
                        <ADescriptions-item label="Nomor Referensi" :span="3">{{ state.detail.no_survey }}</ADescriptions-item>
                        <ADescriptions-item label="Jam Check-In" :span="3">{{ state.detail.checkin_time }}</ADescriptions-item>
                        <ADescriptions-item label="Jam Check-Out" :span="3">{{ state.detail.checkout_time }}</ADescriptions-item>
                    </ADescriptions>
                </ASkeleton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-3 col-md-6 text-center mb-2">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.loading">
                            <h1>{{ state.detail.jarakCheckin }}</h1>
                            <strong>Jarak check-in dari lokasi toko</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center mb-2">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.loading">
                            <h1>{{ state.detail.durasiSurvey }}</h1>
                            <strong>Durasi melakukan Survei Pelanggan</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center mb-2">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.loading">
                            <h1>{{ state.detail.totalProductSurvey }}</h1>
                            <strong>Produk di-survey</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center mb-2">
                <div class="card">
                    <div class="card-body my-5">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.loading">
                            <h1>{{ state.detail.totalProductOrder }}</h1>
                            <strong>Produk di-order</strong>
                        </ASkeleton>
                    </div>
                </div>
            </div>
        </div>

        <ATabs
            class="mt-4"
            v-model:activeKey="state.activeKey">
            <ATabPane
                key="1"
                tab="Survei Produk">
                <TableStandard
                    :url="state.endpoint.product"
                    :columns="state.columns.product"
                    :params="state.params"
                    @errors="errorMessage"/>
            </ATabPane>
            <ATabPane
                key="2"
                tab="Keluhan">
                <TableStandard
                    :url="state.endpoint.complaint"
                    :columns="state.columns.complaint"
                    :params="state.params"
                    @errors="errorMessage"/>
            </ATabPane>
            <ATabPane
                key="3"
                tab="Survei Promo">
                <TableStandard
                    :url="state.endpoint.promotion"
                    :columns="state.columns.promotion"
                    :params="state.params"
                    @errors="errorMessage"/>
            </ATabPane>
            <ATabPane
                key="4"
                tab="Survei Order">
                <TableStandard
                    :url="state.endpoint.order"
                    :columns="state.columns.order"
                    :params="state.params"
                    @errors="errorMessage"/>
            </ATabPane>
            <ATabPane
                key="5"
                tab="Survei Proyek">
                <TableStandard
                    :url="state.endpoint.project"
                    :columns="state.columns.project"
                    :params="state.params"
                    @errors="errorMessage"/>
            </ATabPane>
            <ATabPane
                key="6"
                tab="Survei Foto">
                <div class="carousel-container">
                    <ACarousel autoplay arrows>
                        <template #prevArrow>
                            <div class="custom-slick-arrow" style="left:10px;">
                                <LeftCircleOutlined />
                            </div>
                        </template>
                        <template #nextArrow>
                            <div class="custom-slick-arrow" style="right:10px;">
                                <RightCircleOutlined />
                            </div>
                        </template>

                        <!-- galery images -->
                        <div v-for="(item, index) in state.photo_data" :key="index" class="carousel-item">
                            <a-image :src="item.photos" :alt="`Image ${item.item}`" class="carousel-image" />
                            <div class="carousel-description">
                                {{ item.item }}
                            </div>
                        </div>
                    </ACarousel>
                </div>
            </ATabPane>
        </ATabs>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue'
import TableStandard from '@/components/Molecules/TableStandard'
import { FormatNumber } from '@/helpers/utils'
import apiClient from '@/services/axios'
import moment from 'moment'
import _ from 'lodash'

export default defineComponent({
    components: {
        TableStandard,
        LeftCircleOutlined,
        RightCircleOutlined,
    },
    setup() {
        const route = useRoute()
        const errorMessage = ref()
        const state = reactive({
            columns: {
                product: [ // survey produk
                    {
                        title: 'Produsen',
                        dataIndex: 'produsen',
                    },
                    {
                        title: 'Merek/Kemasan',
                        customRender({ text, record }) {
                            return {
                                children: `${record.product_code} - ${record.product_name}`,
                            }
                        },
                    },
                    {
                        title: 'Berat',
                        dataIndex: 'weight',
                    },
                    {
                        title: 'Harga Beli',
                        dataIndex: 'cost_price',
                    },
                    {
                        title: 'Harga Jual',
                        dataIndex: 'sale_price',
                    },
                    {
                        title: 'TOP',
                        dataIndex: 'top',
                    },
                    {
                        title: 'Stok',
                        dataIndex: 'stock',
                    },
                    {
                        title: 'Volume Penjualan Per Bulan',
                        dataIndex: 'volume_sales_per_month',
                    },
                ],
                promotion: [ // survey promo
                    {
                        title: 'Nama Promo',
                        dataIndex: 'promotion_name',
                    },
                    {
                        title: 'Tipe Promo',
                        dataIndex: 'description',
                    },
                    {
                        title: 'Tanggal Mulai',
                        dataIndex: 'start_date',
                    },
                    {
                        title: 'Tanggal Selesai',
                        dataIndex: 'end_date',
                    },
                    {
                        title: 'Nilai Promo',
                        dataIndex: 'promo_value',
                    },
                    {
                        title: 'Setiap Pembelian',
                        dataIndex: 'every_purchase',
                    },
                ],
                order: [ // order produk
                    {
                        title: 'Kode Produk',
                        dataIndex: 'product_code',
                    },
                    {
                        title: 'Nama Produk',
                        dataIndex: 'product_name',
                    },
                    {
                        title: 'Brand',
                        dataIndex: 'brand',
                    },
                    {
                        title: 'Kemasan',
                        dataIndex: 'uom',
                    },
                    {
                        title: 'Order Qty',
                        dataIndex: 'quantity',
                    },
                ],
                project: [ // survey proyek
                    {
                        title: 'Name Proyek',
                        dataIndex: 'project',
                    },
                    {
                        title: 'Kategori',
                        dataIndex: 'category',
                    },
                    {
                        title: 'Tanggal Mulai',
                        dataIndex: 'start_date',
                    },
                    {
                        title: 'Tanggal Selasai',
                        dataIndex: 'end_date',
                    },
                    {
                        title: 'Produsen',
                        dataIndex: 'supplier',
                    },
                    {
                        title: 'Produk',
                        dataIndex: 'product',
                    },
                    {
                        title: 'Volume',
                        dataIndex: 'volume',
                    },
                ],
                complaint: [
                    {
                        title: 'Kode Produk',
                        dataIndex: 'product_code',
                        width: 150,
                    },
                    {
                        title: 'Nama Produk',
                        dataIndex: 'product_name',
                    },
                    {
                        title: 'Kualitas',
                        dataIndex: 'kualitas',
                    },
                    {
                        title: 'Daya Saing',
                        dataIndex: 'daya_saing',
                    },
                    {
                        title: 'Pengiriman',
                        dataIndex: 'pengiriman',
                    },
                    {
                        title: 'Pelayanan',
                        dataIndex: 'pelayanan',
                    },
                ],
            },
            endpoint: {
                product: `/api/report-ats/detail-survey-product/${route.params.id}`,
                order: `/api/report-ats/detail-order-product/${route.params.id}`,
                project: `/api/report-ats/detail-survey-project/${route.params.id}`,
                complaint: `/api/report-ats/detail-survey-complaint/${route.params.id}`,
                complaint_column: `/api/report-ats/ats-complaints`,
                promotion: `/api/report-ats/detail-product-promotion/${route.params.id}`,
                photo: `/api/report-ats/detail-survey-photos/${route.params.id}`,
            },
            params: {
                page: 1,
                "per-page": 10,
            },
            activeKey: '1',
            loading: false,
            photo_data: [],
            detail: {},
        })

        const fetchDataTotal = () => {
            state.loading = true            
            apiClient
                .get(`/api/report-ats/detail-hasil-survey/${route.params.id}`)
                .then(({ data }) => {
                    state.detail = {
                        ...data,
                        tgl_survey: moment(data.tgl_survey).format('DD MMMM YYYY'),
                        checkout_time: moment(data.checkout_time).format('HH:mm:ss'),
                        checkin_time: moment(data.checkin_time).format('HH:mm:ss'),
                    }
                })
                .finally(() => {
                    state.loading = false
                })
        }
        
        const fetchPhotoDetail = () => {
            apiClient
                .get(state.endpoint.photo)
                .then(({ data }) => {
                    state.photo_data = data.items
                })
        }

        onMounted(() => {
            fetchDataTotal()
            fetchPhotoDetail()
        })

        return {
            fetchDataTotal,
            state,
            errorMessage,
            FormatNumber,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;

    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.carousel-container {
    width: 600px;
    margin: 0 auto;

    .carousel-item {
        position: relative;
        text-align: center;

        .carousel-image {
            width: 100%;
        }

        .carousel-description {
            z-index: 1;
            color: white;
            text-align: center;
            top: 3rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 0;
            font-size: 1.5rem;
        }
    }
}

.ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 160px;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    z-index: 1;
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
    color: #fff;
}
</style>